import React, { ChangeEvent, useEffect } from "react"
import { Layout } from "../components/Layout"
import { Cell } from "baseui/layout-grid"
import { Button } from "../components/Button"
import { useForm, Controller, Control, FieldError } from "react-hook-form"
import { Input } from "../components/Input"
import { FormControl } from "../components/FormControl"
import { useLocation, useHistory, Link } from "react-router-dom"
import { Select, SearchSelect } from "../components/Select"
import { RunIdleTimer } from "../components/RunIdleTimer"
import { Value } from "baseui/select"
import {
  getIndustries,
  sendSignup,
  searchIndustries,
  sendJoinEvent
} from "../api"
import { ParagraphMedium } from "baseui/typography"
import {
  getStateByPostcode,
  State,
  isAutoconfirmState,
  getDeepestIndustry
} from "../util"
import { Person } from "../App"
import { Notification } from "baseui/notification"
import { useStyletron } from "baseui"
import { Checkbox } from "../components/Checkbox"
import { WrappedAddressSearch } from "../components/AddressPicker"
import { getConstant } from "../constants"
interface EmploymentDetailsFormProps {
  setPerson: (person: Person | ((oldPerson: Person) => Person)) => void
  person: Person
  manual: boolean
  resetPersonalDetails: (completeReset?: boolean) => void
}

export interface Coverage {
  areaname: string
  branch_code: string
  branch_id: string
  description: string | null
  full_name: string
  short_name: string
  industry_id: string
  is_contested: boolean
  payment_mode: "bypass" | "stored" | "fatzebra" | "netsuite"
  website: string
  apca_id?: string
  imis_branch: boolean | null
}

export interface Industry {
  code: string
  coverage?: Coverage[]
  industry_id: string
  industry_url: string
  name: string
  children?: Industry[]
}

const workTypeOptions = [
  {
    id: "fulltime",
    label: "Full-time"
  },
  {
    id: "parttime",
    label: "Part-time"
  },
  {
    id: "casual",
    label: "Casual"
  },
  {
    id: "contractor",
    label: "Contractor"
  },
  {
    id: "apprentice",
    label: "Apprentice"
  },
  {
    id: "trainee",
    label: "Trainee"
  },
  {
    id: "student",
    label: "Student"
  }
]

export type JobType =
  | "contractor"
  | "parttime"
  | "fulltime"
  | "casual"
  | "apprentice"
  | "trainee"
  | "student"

const incomeBands = [
  { min: 0, max: 15599, label: "up to $15,599" },
  { min: 15600, max: 31999, label: "$15,600 - $31,999" },
  { min: 32000, max: 51999, label: "$32,000 - $51,999" },
  { min: 52000, max: 77999, label: "$52,000 - $77,999" },
  { min: 78000, max: 103999, label: "$78,000 - $103,999" },
  { min: 104000, max: 129999, label: "$104,000 - $129,999" },
  { min: 130000, max: 159999, label: "$130,000 - $159,999" },
  { min: 160000, max: 199999, label: "$160,000 - $199,999" },
  { min: 200000, max: 249999, label: "$200,000 - $249,999" },
  { min: 250000, max: Infinity, label: "$250,000 and above" }
]

function determineIncomeBand(pay: number) {
  const band = incomeBands.find(band => pay >= band.min && pay <= band.max)
  return band ? band.label : "Salary band not found"
}

interface FormValues {
  job_title: string
  duties: string
  employer: string
  industry_1: Value | null
  industry_2: Value | null
  industry_3: Value | null
  industry_4: Value | null
  job_search: {
    label: string
    value: Value | null | undefined
  }
  hours_per_week: number
  // this field is not stored as part of person,
  // just used within form to hold API lookup results
  employer_address: string | null
  employer_suburb: string | null
  employer_postcode: string | null
  pay: number
  income_band: string | null
  job_type: JobType
  cant_find_job: boolean
  filledOutEmployerDetails: boolean
}

interface CategorySelectProps {
  value?: Value
  onChange: (option: Value | undefined) => void
  parent: string | null
  label: string
  state?: State | null
  caption?: React.ReactNode
  disabled: boolean
}

interface SelectOption {
  id?: string
  label: string
  industry: Industry
}
interface JobTypeSelectProps {
  value?: string
  onChange: (option: string) => void
  error?: string
}

export const JobTypeSelect = (props: JobTypeSelectProps) => {
  let value: Value = []
  workTypeOptions.forEach(option => {
    if (option.id === props.value) {
      value = [option]
    }
  })
  return (
    <FormControl
      error={props.error}
      label="Employment Status"
      overrides={{ ControlContainer: { style: { maxWidth: "300px" } } }}
    >
      <Select
        overrides={{
          Input: {
            props: {
              id: `job_type`,
              name: `job_type`
            }
          },
          Dropdown: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
        placeholder={"Please Choose"}
        options={workTypeOptions}
        required={true}
        onChange={value => props.onChange(value.value[0].id as string)}
        value={value}
        backspaceRemoves={false}
        clearable={false}
        searchable={false}
      />
    </FormControl>
  )
}

export const IndustrySelect = ({
  value,
  onChange,
  parent,
  label,
  state,
  caption,
  disabled
}: CategorySelectProps) => {
  const [categories, setCategories] = React.useState<Value>()
  React.useEffect(() => {
    getIndustries(parent, state)
      .then(response => {
        const industries = response.industries
          ? response.industries
          : response.industry && response.industry.children
        if (industries) {
          const options = industries
            .map(industry => {
              return {
                label: industry.name.replace("&amp;", "&"),
                id: industry.industry_id.toString(),
                industry
              } as SelectOption
            })
            .sort((a: { label: string }, b: { label: string }) =>
              a.label.localeCompare(b.label)
            )
          setCategories(options)
        }
      })
      .catch(() => {
        setCategories([])
      })
  }, [parent, state])

  return (
    <FormControl
      error={false}
      label={label}
      overrides={{ ControlContainer: { style: { maxWidth: "300px" } } }}
      caption={caption}
    >
      <Select
        disabled={disabled}
        clearable={false}
        placeholder={"Please Choose"}
        options={categories}
        onChange={value => onChange(value.value)}
        value={value}
        overrides={{
          Input: {
            props: {
              id: `industry_${parent}`,
              name: `industry_${parent}`
            }
          },
          Dropdown: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
      />
    </FormControl>
  )
}

export type ValueOrNullOrUndefined = Value | null | undefined

export interface JobSearchProps {
  state: State
  onChange: (value: { label: string; value: Value | null } | null) => void
  value?: {
    label: string
    value: Value
  }
  error?: FieldError | undefined
  disabled: boolean
}

export const JobSearch = ({
  state,
  onChange,
  value,
  error,
  disabled
}: JobSearchProps) => {
  const [suggestions, setSuggestions] = React.useState<Value>([])
  const getNewSuggestions = (query: string) => {
    if (state) {
      searchIndustries(query, state)
        .then(results => {
          setSuggestions(
            results.results.map(industry => {
              return {
                id: industry.industry_id,
                label: industry.display_name,
                industry: industry
              }
            })
          )
        })
        .catch(() => {
          setSuggestions([])
        })
    }
  }
  const { pathname } = useLocation()
  const manual_link = `manual-${pathname.replace("/", "")}`
  return (
    <FormControl
      error={!!error}
      label={"Job Search"}
      overrides={{ ControlContainer: { style: { maxWidth: "600px" } } }}
      caption={() => (
        <span>
          {error ? `${error.message}. ` : ""}
          <Link to={manual_link}>
            If you can't find your job, try searching by industry
          </Link>
        </span>
      )}
    >
      <SearchSelect
        disabled={disabled}
        onBlurResetsInput={false}
        onCloseResetsInput={false}
        openOnClick={false}
        type={"search"}
        placeholder={"Please Choose"}
        filterOptions={options => options}
        options={suggestions}
        clearable
        value={value && value.value ? value.value : []}
        onChange={value => {
          if (value.option && value.option.industry.display_name) {
            onChange({
              label: value.option && value.option.industry.display_name,
              value: value.value
            })
          } else {
            onChange(null)
          }
        }}
        onInputChange={e => {
          if (e.currentTarget.value) {
            getNewSuggestions(e.currentTarget.value)
            onChange({ label: e.currentTarget.value, value: null })
          } else {
            onChange(null)
          }
        }}
        overrides={{
          Input: {
            props: {
              id: `job_search`,
              name: `job_search`
            }
          },
          Dropdown: {
            style: {
              maxHeight: "200px"
            }
          }
        }}
      />
    </FormControl>
  )
}

export interface ManualIndustrySectionProps
  extends Pick<FormValues, "industry_1" | "industry_2" | "industry_3"> {
  state: State
  setValue: (property: string, value: any) => void
  control: Control
  disabled: boolean
}
export function ManualIndustrySection({
  industry_1,
  industry_2,
  industry_3,
  state,
  setValue,
  control,
  disabled
}: ManualIndustrySectionProps) {
  const autoconfirmState = isAutoconfirmState(state)
  const industry_1_id =
    industry_1 && industry_1[0].industry.coverage === null
      ? industry_1[0].id
      : null
  const industry_2_id =
    industry_2 && industry_2[0].industry.coverage === null && autoconfirmState
      ? industry_2[0].id
      : null
  const industry_3_id =
    industry_3 && industry_3[0].industry.coverage === null && autoconfirmState
      ? industry_3[0].id
      : null

  const { pathname } = useLocation()
  const search_link = pathname.replace("/manual-", "")
  return (
    <>
      <Cell span={[4, 8, 12]}>
        <Controller
          parent={null}
          disabled={disabled}
          state={state}
          onChange={([selected]: Value[]) => {
            // Probably some refactoring can happen here.
            if (selected && selected[0]) {
              const [{ id }] = selected
              if (industry_1_id !== id) {
                setValue("industry_2", null)
                setValue("industry_3", null)
                setValue("industry_4", null)
              }
            } else {
              setValue("industry_2", null)
              setValue("industry_3", null)
              setValue("industry_4", null)
            }
            return selected
          }}
          as={IndustrySelect}
          caption={
            autoconfirmState ? (
              <Link to={search_link}>
                If you can't find your industry, try searching by job title
              </Link>
            ) : (
              ""
            )
          }
          name="industry_1"
          label="Which industry do you work in?"
          control={control}
        />
      </Cell>
      <Cell span={[4, 8, 12]}>
        {industry_1_id && (
          <Controller
            state={state}
            disabled={disabled}
            parent={industry_1_id as string}
            onChange={([selected]: Value[]) => {
              if (selected && selected[0]) {
                const [{ id }] = selected
                if (industry_2_id !== id) {
                  setValue("industry_3", null)
                  setValue("industry_4", null)
                }
              } else {
                setValue("industry_3", null)
                setValue("industry_4", null)
              }
              return selected
            }}
            as={IndustrySelect}
            name="industry_2"
            label="Which sub-industry?"
            control={control}
          />
        )}
      </Cell>
      <Cell span={[4, 8, 12]}>
        {industry_2_id && (
          <Controller
            state={state}
            disabled={disabled}
            parent={industry_2_id as string}
            onChange={([selected]: Value[]) => {
              if (selected && selected[0]) {
                const [{ id }] = selected
                if (industry_3_id !== id) {
                  setValue("industry_4", null)
                }
              } else {
                setValue("industry_4", null)
              }
              return selected
            }}
            as={IndustrySelect}
            name="industry_3"
            label="Which part of that sub-industry?"
            control={control}
          />
        )}
      </Cell>
      <Cell span={[4, 8, 12]}>
        {industry_3_id && (
          <Controller
            state={state}
            disabled={disabled}
            parent={industry_3_id as string}
            as={IndustrySelect}
            name="industry_4"
            label="Which job?"
            control={control}
          />
        )}
      </Cell>
    </>
  )
}

export function getInitialIndustryAndJobValues(person: Person) {
  const deepestIndustry = getDeepestIndustry(
    person.industry_1,
    person.industry_2,
    person.industry_3,
    person.industry_4,
    person.job_search
  )
  if (
    !(
      deepestIndustry &&
      deepestIndustry.coverage &&
      deepestIndustry.coverage[0].areaname ===
        getStateByPostcode(person.postcode)
    )
  ) {
    return {
      industry_1: null,
      industry_2: null,
      industry_3: null,
      industry_4: null,
      job_search: undefined
    }
  } else {
    return {
      industry_1: person.industry_1,
      industry_2: person.industry_2,
      industry_3: person.industry_3,
      industry_4: person.industry_4,
      job_search: person.job_search
    }
  }
}
export const EmploymentDetailsForm = ({
  setPerson,
  resetPersonalDetails,
  person,
  manual
}: EmploymentDetailsFormProps) => {
  useEffect(() => {
    // when it mounts, null the industries if the postcode is different
    setPerson(oldPerson => {
      const initialIndustryAndJobValues = getInitialIndustryAndJobValues(
        oldPerson
      )
      return {
        ...oldPerson,
        ...initialIndustryAndJobValues
      }
    })
  }, [setPerson])
  const { push } = useHistory()
  useEffect(() => {
    if (!person.people_id || !person.person_id) {
      push("/")
    }
  }, [person.people_id, person.person_id, push])
  const { handleSubmit, register, setValue, watch, control, errors } = useForm<
    FormValues
  >({
    defaultValues: {
      duties: person.duties,
      employer: person.employer,
      employer_address: person.employer_address,
      employer_postcode: person.employer_postcode,
      employer_suburb: person.employer_suburb,
      hours_per_week: person.hours_per_week,
      job_type: person.job_type,
      pay: person.pay,
      income_band: person.income_band,
      cant_find_job: person.cant_find_job,
      job_title: person.job_title,
      filledOutEmployerDetails: person.filledOutEmployerDetails,
      ...getInitialIndustryAndJobValues(person)
    }
  })
  const [formError, setFormError] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [scrollTrigger, setScrollTrigger] = React.useState(1)
  const topError =
    formError ||
    (Object.keys(errors).length > 0
      ? "Please fill in the required information below"
      : "")
  const [css] = useStyletron()
  useEffect(() => {
    if (!(person && person.postcode)) {
      push("/")
    }
  }, [person, push])
  const postcode: string = person.postcode as string
  const industry_1 = watch("industry_1")
  const industry_2 = watch("industry_2")
  const industry_3 = watch("industry_3")
  const industry_4 = watch("industry_4")
  const job_search = watch("job_search")
  let employer_suburb = ""
  const deepestIndustry = getDeepestIndustry(
    industry_1,
    industry_2,
    industry_3,
    industry_4,
    job_search
  )
  const state = getStateByPostcode(postcode)
  const [pay, setPay] = React.useState(person.pay || "")
  const [showPayAlert, setShowPayAlert] = React.useState()
  const handlePayChange = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredPay = event.target.value
    const hasNonDigits = /\D/.test(enteredPay)
    setShowPayAlert(hasNonDigits)
    setPay(enteredPay.replace(/\D+/, ""))
  }
  const incomeBand = pay ? determineIncomeBand(Number(pay)) : ""
  const lowBandIncomeMessage =
    pay.toString().length > 0 && pay.toString().length <= 4
  const onSubmit = (values: FormValues) => {
    setFormError("")
    setIsLoading(true)
    const updated_person = {
      ...person,
      ...values,
      income_band: incomeBand,
      employer_suburb: employer_suburb,
      last_work_details_path: manual
        ? "/manual-employment-details"
        : "/employment-details",
      industry: deepestIndustry,
      filledOutEmployerDetails: true
    }
    sendSignup(updated_person)
      .then(response => {
        setPerson({
          ...updated_person,
          cost: response && response.cost,
          person_id: response && response.person_id,
          people_id: response && response.people_id
        })
        sendJoinEvent(
          response.person_id,
          response.people_id,
          getConstant("joinEmploymentDetailsEvent")
        )
        push(`/union`)
      })
      .catch(() => {
        setFormError(
          "We're having issues submitting your details, please check your details and try again later"
        )
        setIsLoading(false)
        setScrollTrigger(value => value + 1)
      })
  }
  const buttonText = "Next"

  const button = (
    <Button
      overrides={{ BaseButton: { style: { marginBottom: "0" } } }}
      isLoading={isLoading}
    >
      {buttonText}
    </Button>
  )
  const cant_find_job = watch("cant_find_job")

  const setEmployerSuburb = (value: string | null) => {
    if (value) {
      employer_suburb = value
    }
  }
  return (
    <form id="employment" onSubmit={handleSubmit(onSubmit)}>
      <RunIdleTimer resetPersonalDetails={resetPersonalDetails} />
      <Layout
        heading="Work Details"
        sectionStep={1}
        person={person}
        setPerson={setPerson}
        scrollTrigger={scrollTrigger}
      >
        {topError && (
          <Cell span={[4, 8, 12]}>
            <Notification
              overrides={{ Body: { style: { width: "auto" } } }}
              kind="negative"
            >
              {topError}
            </Notification>
          </Cell>
        )}
        <Cell span={[4, 8, 12]}>
          {!manual && (
            <Controller
              as={JobSearch}
              disabled={cant_find_job}
              rules={{
                required: cant_find_job ? false : "Please enter your job"
              }}
              error={(errors.job_search as unknown) as FieldError}
              state={state}
              control={control}
              name="job_search"
            />
          )}
        </Cell>
        {manual && state && (
          <ManualIndustrySection
            disabled={cant_find_job}
            industry_1={industry_1}
            industry_2={industry_2}
            industry_3={industry_3}
            control={control}
            state={state}
            setValue={setValue}
          />
        )}
        <Cell span={[4, 8, 12]}>
          <Controller
            control={control}
            overrides={{
              Root: {
                style: {
                  marginTop: "16px",
                  marginBottom: "16px"
                }
              }
            }}
            name="cant_find_job"
            as={Checkbox}
            onChange={([e]) => {
              setValue("industry_1", null)
              setValue("industry_2", null)
              setValue("industry_3", null)
              setValue("industry_4", null)
              setValue("job_search", {
                label: "",
                value: null
              })
              return e.currentTarget.checked
            }}
            valueName="checked"
          >
            I’m having trouble finding my job/industry
          </Controller>
        </Cell>
        <Cell span={[4, 8, 12]}>
          {cant_find_job && (
            <div
              className={css({
                backgroundColor: "#eaeaea",
                borderRadius: "5px",
                padding: "24px",
                marginTop: "8px",
                marginBottom: "24px"
              })}
            >
              <ParagraphMedium
                overrides={{
                  Block: { style: { marginTop: 0, marginBottom: 0 } }
                }}
              >
                If you are unable to find your job/industry, please complete the
                rest of the form and an Australian Unions team member will
                contact you to finalise your join application.
              </ParagraphMedium>
            </div>
          )}
        </Cell>
        <Cell span={[4, 8, 12]}>
          {(manual || cant_find_job) && (
            <FormControl
              error={errors.job_title && errors.job_title.message}
              label="Job Title"
            >
              <Input
                inputRef={register({ required: "Please enter your job title" })}
                overrides={{ InputContainer: { style: { width: "300px" } } }}
                error={!!errors.job_title}
                id="job_title"
                name="job_title"
              />
            </FormControl>
          )}
        </Cell>
        <Cell span={[4, 8, 12]}>
          <FormControl
            error={errors.duties && errors.duties.message}
            label="What are your main duties at work?"
          >
            <Input
              inputRef={register({ required: "Please enter your main duties" })}
              overrides={{ InputContainer: { style: { width: "300px" } } }}
              error={!!errors.duties}
              id="duties"
              name="duties"
            />
          </FormControl>
        </Cell>
        <Cell span={[4, 8, 12]}>
          <FormControl
            label="Organisation/Company"
            caption={
              errors.employer
                ? errors.employer.message
                : "We only share this information with your union. We won't share it with your employer."
            }
            error={!!errors.employer}
          >
            <Input
              inputRef={register({
                required:
                  "Please enter your employer. We only share this information with your union. We won't share it with your employer."
              })}
              overrides={{ InputContainer: { style: { width: "300px" } } }}
              error={!!errors.employer}
              id="employer"
              name="employer"
            />
          </FormControl>
        </Cell>

        <Cell span={[4, 8, 12]}>
          <Controller
            as={WrappedAddressSearch}
            addressType="postcode"
            setPostcode={
              (employer_postcode: string | null) =>
                setValue("employer_postcode", employer_postcode) //setValue
            }
            error={
              errors.employer_address
                ? (errors.employer_address as any)
                : undefined
            }
            setSuburb={(employer_address: string | null) =>
              setEmployerSuburb(employer_address)
            }
            control={control}
            rules={{
              required: "Please enter your employer's suburb"
            }}
            valueName="inputValue"
            name="employer_address"
            fieldLabel="Workplace Suburb"
            fieldName="employer_suburb"
          />
        </Cell>
        <Cell span={[4, 8, 12]}>
          <FormControl
            error={errors.employer_postcode && errors.employer_postcode.message}
            label="Workplace Postcode"
          >
            <Input
              inputRef={register({
                required: "Please enter your employer's postcode",
                pattern: {
                  value: /^(0[28-9]\d{2})|([1-9]\d{3})$/,
                  message: "Must be a 4 digit number and a valid postcode"
                }
              })}
              overrides={{
                InputContainer: { style: { width: "100px" } },
                Input: {
                  props: {
                    maxLength: "4"
                  }
                }
              }}
              error={!!errors.employer_postcode}
              type="tel"
              id="employer_postcode"
              name="employer_postcode"
            />
          </FormControl>
        </Cell>
        <Cell span={[4, 8, 12]}>
          <Controller
            as={JobTypeSelect}
            rules={{ required: "Please select your job type" }}
            control={control}
            name="job_type"
            error={errors.job_type && (errors.job_type.message as string)}
          />
        </Cell>
        <Cell span={[4, 8, 12]}>
          <FormControl
            error={errors.hours_per_week && errors.hours_per_week.message}
            label="Hours per Week"
          >
            <Input
              inputRef={register({
                required: "Please enter your hours per week",
                pattern: {
                  value: /^[1-9][0-9]?$/,
                  message: "Your hours should be a whole number between 1-99"
                }
              })}
              overrides={{
                InputContainer: { style: { width: "100px" } },
                Input: {
                  props: {
                    maxLength: "4"
                  }
                }
              }}
              error={!!errors.hours_per_week}
              type="tel"
              id="hours_per_week"
              name="hours_per_week"
            />
          </FormControl>
        </Cell>
        <Cell span={[4, 8, 12]}>
          <FormControl
            error={!!errors.pay}
            label="Personal Annual Income"
            caption={
              (errors.pay ? errors.pay.message : "") +
              "Your income will be used to determine your fees."
            }
          >
            <Input
              onChange={handlePayChange}
              value={pay}
              inputRef={register({
                required:
                  "Please enter your annual income in whole numbers only. ",
                pattern: {
                  value: /^(\d+)$/,
                  message: "Your income should be a whole number."
                }
              })}
              overrides={{
                InputContainer: { style: { width: "180px" } },
                Input: {
                  props: {
                    maxLength: "9"
                  }
                }
              }}
              error={!!errors.pay}
              type="tel"
              id="pay"
              name="pay"
            />
          </FormControl>
          {showPayAlert && (
            <div style={{ color: "red" }}>
              Enter your income in numbers only.
            </div>
          )}
          <Notification kind={"warning"}>
            <div>
              <ParagraphMedium marginTop={0} marginBottom={0}>
                Your yearly income bracket based on your pay:{" "}
                <strong>{incomeBand}</strong>
              </ParagraphMedium>
              <ParagraphMedium marginTop={"8px"} marginBottom={0}>
                {lowBandIncomeMessage
                  ? "Is this income range correct? Edit your pay details above to modify."
                  : "Edit your pay details above to modify."}
              </ParagraphMedium>
            </div>
          </Notification>
        </Cell>
        <Cell span={[4, 8, 12]}>{button}</Cell>
      </Layout>
    </form>
  )
}
